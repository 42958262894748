<template>
  <Popup
    size="lg"
    modal-id="content-notes"
    :on-close="onModalClose"
    :scrollable="true"
    :toggle-status="showPopup"
  >
    <template v-slot:header>
      {{ labels.notes }}
    </template>

    <template v-slot:body>
      <AuthorPost
        v-for="(item, index) in notes"
        :key="index"
        :data="item"
        @updatedPost="takeUpdateNote"
        @removeNote="deleteNote"
        @editPostStatus="updateNoteChangeStatus"
      />
      <div v-if="firstDataLoadPassed && !editingNote" class="text-editor">
        <TextEditorCard
          :update-key="editorUpdateKey"
          @changed="takeANote"
          :value="data.text"
        />
        <Button
          class="publish-button"
          :label="labels.send"
          variant="btn-purple"
          :styles="{ padding: '8px 15px', fontWeight: 700 }"
          :action="() => addNote()"
        />
      </div>
    </template>

    <template v-slot:footer>
      <Button
        variant="btn-outline-purple"
        :label="labels.close"
        :action="onModalClose"
      />
    </template>
  </Popup>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Popup from "@/components/Popups/Popup";
import AuthorPost from "@/components/Author/AuthorPost";
import TextEditorCard from "@/components/Input/TextEditorCard";
import Button from "@/components/Buttons/Button";

export default {
  name: "ContentNotes",
  components: {
    Popup,
    AuthorPost,
    TextEditorCard,
    Button,
  },
  props: {
    routeBack: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      notes: [],
      showPopup: true,
      data: {
        id: this.id,
        text: "",
      },
      firstDataLoadPassed: false,
      editorUpdateKey: Date.now(),
      editingNote: false,
    };
  },
  computed: {
    ...mapState("_module", {
      singleData: "singleData",
    }),
    ...mapGetters("helper", ["labels"]),
    ...mapState("user", ["username", "userFullName", "userid"]),
    id() {
      return this.$route.params.id;
    },
    singleDataNotes() {
      return this.singleData?.content?.notes;
    },
  },
  methods: {
    ...mapActions("_module", {
      addNoteAction: "addNote",
      deleteNoteAction: "deleteNote",
      updateNoteAction: "updateNote",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    onModalClose() {
      this.$router.push({
        name: "module-editor",
        params: {
          id: this.$route.params.id,
        },
      });

      let element = document.querySelector(".modal-backdrop");
      if (element) {
        element.remove();
        document.querySelector("body").classList.remove("modal-open");
      }
    },

    takeANote(data) {
      this.data.text = data.value;
    },
    loadNotes() {
      this.notes = this.singleDataNotes;
      this.generateNotes();
    },
    generateNotes() {
      if (
        this.singleData.content.notes &&
        Array.isArray(this.singleData.content.notes)
      ) {
        this.notes = [];

        this.singleData.content.notes.forEach((note, index) => {
          this.notes.push({
            _id: note._id,
            image: note.user?.avatar || "no-image",
            name: this.noteAuthorName(note.user),
            date: note.created?.slice(0, 10) || "",
            time: note.created?.slice(11, 16) || "",
            post: note.text,
            user: note?.user?._id,
            key: index,
          });
        });
        if (!this.firstDataLoadPassed) this.firstDataLoadPassed = true;
      }
    },
    takeUpdateNote(updatedNote) {
      if (!updatedNote.post?.length) return;

      this.updateNoteAction({
        id: this.singleData.content._id,
        note_id: updatedNote._id,
        text: updatedNote.post,
      })
        .then((res) => {
          if (res.success) {
            this.notes[updatedNote.key].text = updatedNote.post;
            this.addNotification({
              variant: "success",
              msg: this.labels.updated_note,
              dismissSecs: 5,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    deleteNote(deletedNote) {
      this.deleteNoteAction({
        id: this.singleData.content._id,
        note_id: deletedNote._id,
        text: deletedNote.post,
      })
        .then((res) => {
          if (res.success) {
            this.notes = this.notes.filter(
              (note) => note._id !== deletedNote._id
            );
            this.addNotification({
              variant: "success",
              msg: this.labels.deleted_note,
              dismissSecs: 5,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    noteAuthorName(user) {
      if (user?.name?.first && user?.name?.middle && user?.name?.last)
        return `${user?.name.first} ${user?.name.middle} ${user?.name.last}`;
      if (user?.name?.first && user?.name?.last)
        return `${user?.name.first} ${user?.name.last}`;

      if (user?.username) return user?.username;

      return this.labels.unknown;
    },
    addNote() {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      let content = this.data.text;

      this.addNoteAction({ id: this.singleData.content._id, content: content })
        .then((res) => {
          if (res.success) {
            this.notes.push({
              post: content,
              _id: res.data.notes[res.data.notes.length - 1]._id,
              user: this.userid,
              created: new Date().toISOString(),
              date: new Date().toISOString().slice(0, 10) || "",
              time: `${hours}:${minutes}`,
              name: this.noteAuthorName({
                _id: this.userid,
                username: this.username,
                name: this.userFullName,
              }),
            });

            this.data.text = "";
            this.editorUpdateKey = Date.now();

            this.addNotification({
              variant: "success",
              msg: this.labels.added_note,
              dismissSecs: 5,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    updateNoteChangeStatus(status) {
      this.editingNote = status;
    },
  },
  watch: {
    singleData(newVal, oldVal) {
      if (
        typeof oldVal == "object" &&
        !oldVal.content?.notes &&
        typeof newVal == "object" &&
        newVal.content?.notes
      ) {
        this.loadNotes();
      }
    },
  },
  mounted() {
    if (Object.keys(this.singleData).length && this.singleData?.content?.notes)
      this.loadNotes();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.text-editor {
  position: relative;
  .publish-button {
    right: 0;
    bottom: 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
    position: absolute;
    cursor: pointer;
  }
}
</style>
